<template>
  <v-container>
    <v-card flat>
      <v-card-title>
        <v-select dense outlined label="Days" :items="dayOptions" value="value" name="text" v-model="days" @change="fetch">
        </v-select>
      </v-card-title>
      <div>
        <v-card-text class="d-flex justify-center align-item ">
          <v-progress-circular v-if="api.isLoading" :size="50" color="primary" style="align-items: center;position:absolute" indeterminate>
          </v-progress-circular>
          <canvas v-show="!api.isLoading" ref="myChart">
  
          </canvas>
        </v-card-text>
      </div>
    </v-card>
  </v-container>
  </template>
<script>
import Chart from 'chart.js'

export default {
  created(){
    this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
    };
    this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {

      const ctx = this.$refs.myChart.getContext('2d')
      if (this.chart) {
      // Destroy the existing chart instance
      this.chart.destroy();
    }

      let config = {
          type: 'horizontalBar',
          data: {
              labels: resp.courseName,
              datasets: [
              {
                  label: 'Top 10 Trending Masterclass Video',
                  data: resp.totalViews,
                  borderWidth:1,
                  borderColor:'rgb(67, 209, 135)',
                  fill:false,
              },
              ],
          },
          options:{
              indexAxis:'y',
              scaleShowValues:true,
              scales:{
                  xAxes:[{
                  ticks:{
                      autoSkip:true,
                      }
                  }]
              },
              plugins: {
                  legend:{
                  positon:'right',
                  },
                  title: {
                  display:true,
                  text: 'Top 10 popular TaxPOD Videos'
                  }
              }
          }
      }
     
        this.chart = new Chart(ctx,config);

        this.api.isLoading = false;
        this.api.isError = false;
        this.isSucessful = true;
    }
  },
  mounted () {
    this.fetch();
  },
  data: () => ({
    api:{
        isSucessful:false,
        isLoading :false,
        isError:false,
        error:null,
        url:null,
    },
    dayOptions:[
      {
          text:'7 days',
          value:'7'
      },
      {
          text:'30 days',
          value:'30',
      },
      {
          text:'90 days',
          value:'90',
      },
      {
          text:'All times',
          value:'all',
      },
  ],
    days:'90',
    users:null,
    companies:null,
    videos:null,
    userWatches:null,
    datasets:null,
  }),
  methods:{
    fetch(){  
      let fetchPerformanceApi = this.fetchPerformance();
      this.$api.fetch(fetchPerformanceApi);
    },
    fetchPerformance(){
      let tempApi = this.$_.clone(this.api);
      tempApi.method = "GET";
      tempApi.url = process.env.VUE_APP_SERVER_API+"/analysis/trendvideo/"+this.days;
      return tempApi;
    },
  }
}
</script>